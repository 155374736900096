// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/splide/css/core';
import '@splidejs/splide/css/skyblue';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Maps
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';

import {
    Splide,
    SplideTrack,
    SplideSlide,
} from '@splidejs/vue-splide';

import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Dialog,
    DialogPanel,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverPanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TransitionRoot,
    TransitionChild,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogOverlay: DialogOverlay,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        HuiPopover: Popover,
        HuiPopoverButton: PopoverButton,
        HuiPopoverPanel: PopoverPanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        GoogleMapEmbed,
        SplideCarousel: Splide,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        TransitionRoot,
        TransitionChild,
    },

    data() {
        return {
            navActive: false,
            siteSearch: false,
            openModal: true,
        };
    },

    mounted() {


    },

    methods: {
        toggleModal() {
            this.openModal = !this.openModal;

            document.cookie = 'modal=modal; path=/; max-age=2592000;';
        },
    },
}).mount('#app');
